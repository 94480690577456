body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Didact Gothic', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-image {
  background-image: url("./images/header-1.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  background-color:  rgb(0, 92, 106);;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer {
	display: flex;
	padding: 1.5rem 1.5rem;
	line-height: 1.5rem;
  color: white;
	a {
		border-bottom: 1px solid;
		color: black;
	}
	a:hover {
		color: rgb(0, 147, 170);
	}
}

.heightMax {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.heightMax .card-footer {
	margin-top: auto;
 }

.widthMax {
	width: 100%;
}

.hoverable:hover {
    opacity: 0.50;
    transition: .5s ease;
}

.justified-content {
    text-align: justify;
}

.centered-content {
    text-align: center;
}

.map-responsive {
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  min-height:250px;
  }
  
  .map-responsive iframe {
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
  }


$hero-body-padding-medium: 16rem 4.5rem;
$hero-body-padding: 4rem 1.5rem;

$navbar-dropdown-background-color: rgb(0, 92, 106);

$footer-background-color: rgb(0, 92, 106);
$footer-padding : 3rem 1.5rem 0rem;

$card-background-color: rgb(0, 92, 106);
$card-color :white;

$box-color : white;

$title-color :white;

$primary: rgb(0, 92, 106);
$secondary: rgb(0, 147, 170);
$info:  rgb(1, 53, 61);

$family-secondary: 'Didact Gothic';

.zoom{
  transition: transform .4s;
  }
  .zoom:hover{
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5);
    }

@import "~bulma/bulma.sass";

.hero .navbar {
  background: #005c6a;
}

.section {
  font-family: 'Didact Gothic';
}